import { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    Data
} from 'components/page/realEstate/common/advertisements/searchResult/data/Data';
import {
    NoData
} from 'components/page/realEstate/common/advertisements/searchResult/NoData';
import { LIMIT } from 'components/page/realEstate/common/advertisements/constants/limit';

interface ISearchResult {
    itemId: string;
    noData: boolean;
    isAgent?: boolean;
    isLoading: boolean;
    totalPages: number;
    branchType?: string;
    currentPage: number;
    itemSlugName: string;
    advertisements: IAdvertisement[];
    onPageChange: (newPage: number) => void;
}

export const SearchResult: FC<ISearchResult> = ({
    noData,
    itemId,
    isAgent,
    isLoading,
    branchType,
    totalPages,
    currentPage,
    itemSlugName,
    onPageChange,
    advertisements,
}) => {
    return (
        <Container>
            <Box mb={10}>
                {noData
                    ? <NoData isAgent={isAgent}/>
                    : <Data
                        limit={LIMIT}
                        itemId={itemId}
                        isAgent={isAgent}
                        isLoading={isLoading}
                        branchType={branchType}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        itemSlugName={itemSlugName}
                        onPageChange={onPageChange}
                        advertisements={advertisements}
                    />
                }
            </Box>
        </Container>
    );
};
