import React, { FC, FunctionComponent, SVGProps } from 'react';
import { Box, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import Link from 'components/link/Link';
import { Text } from 'modules/theme/components/text/Text';

interface IProps {
    name: string;
    component: FunctionComponent<SVGProps<SVGSVGElement>>;
    href?: string;
    onClick?: () => void;
    rels?: string[];
}
export const IconMobileBadge: FC<IProps> = ({
    name,
    component,
    href,
    onClick,
    rels,
}) => {
    const { palette } = useTheme();

    const onComponentClick = (event) => {
        if (onClick) {
            event.preventDefault();
            onClick();
        }
    };

    return <Link
        sx={{
            height: '100%',
            cursor: 'pointer',
        }}
        href={href}
        rels={rels}
        openInNewTab
        noUnderline
        onClick={onComponentClick}
    >
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            gap={0.5}
            borderRadius={2}
            p={1.5}
            border={'1px solid ' + palette.labelQuarternary.main}
        >
            <Svg icon component={component} width={24} />
            <Text variant='label2'>
                {name}
            </Text>
        </Box>
    </Link>;
};
