import React, { FC } from 'react';
import { IStepProps } from 'components/page/realEstate/common/interfaces/IStepProps';
import { Box, Grid, useTheme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import ArrowBackIcon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import { PhoneBadge } from 'components/page/realEstate/common/drawer/partials/PhoneBadge';
import { IPhoneNumbers } from 'modules/realEstate/common/interfaces/IPhoneNumbers';
import KeyholeIcon from 'modules/theme/components/icons/basic/Keyhole.svg';

interface IProps extends IStepProps {
    phoneNumbers: IPhoneNumbers,
    callText: string
    onMainPhoneClick?: (phone: string) => void;
    onOtherPhoneClick?: (phone: string) => void;
}

export const StepPhoneNumbers: FC<IProps> = ({
    onIconClick,
    phoneNumbers,
    callText,
    onMainPhoneClick,
    onOtherPhoneClick,
}) => {
    const { palette } = useTheme();

    return <>
        <Grid item xs={12}>
            <Link
                sx={{
                    cursor: 'pointer',
                }}
                noUnderline
                href='#'
                onClick={() => {
                    onIconClick();
                }}
            >
                <Box display='flex' alignContent='center' gap={1}>
                    <Svg icon
                        component={ArrowBackIcon}
                    />
                    <Text variant='body3'>
                        Kontaktné údaje
                    </Text>
                </Box>
            </Link>
        </Grid>

        <Grid item xs={12}>
            <Text variant='h6' semibold>
                {callText}
            </Text>
        </Grid>

        <Grid item xs={12}>
            <PhoneBadge phone={phoneNumbers.main} onClick={onMainPhoneClick} />
        </Grid>
        {(phoneNumbers?.others?.length > 0) && (
            phoneNumbers?.others?.map((item, index) => {
                return (
                    <Grid key={index} item xs={12}>
                        <PhoneBadge phone={item} onClick={onOtherPhoneClick} />
                    </Grid>
                );
            })
        )}
        <Grid item xs={12}>
            <Box display='flex' alignItems='center' alignContent='center' gap={2}>
                <Svg icon component={KeyholeIcon} color='primary'/>
                <Text
                    variant='label2'
                    color={palette.labelSecondary}
                >
                    Nezabudnite povedať, že voláte na základe informácií, ktoré ste našli na Nehnuteľnosti.sk
                </Text>
            </Box>
        </Grid>
    </>;
};
