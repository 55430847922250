import { FC } from 'react';
import { Stack } from '@mui/material';
import { Paginator } from 'modules/theme/components/paginator/Paginator';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import {
    Loading
} from 'components/page/realEstate/common/advertisements/searchResult/data/Loading';
import {
    List
} from 'components/page/realEstate/common/advertisements/searchResult/listing/List';
import { ERouterBranchType } from 'modules/route/enums/agency/detail/ERouterBranchType';
import { ERouterTabs as ERouterTabsAgent } from 'modules/route/enums/agent/detail/ERouterTabs';
import { ERouterTabs as ERouterTabsAgency } from 'modules/route/enums/agency/detail/ERouterTabs';
import { REAL_ESTATE_AGENT_DETAIL_TAB, REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';

interface IData {
    limit: number;
    itemId: string;
    isAgent?: boolean;
    isLoading: boolean;
    totalPages: number;
    currentPage: number;
    branchType?: string;
    itemSlugName: string;
    advertisements: IAdvertisement[];
    onPageChange: (newPage: number) => void;
}

export const Data: FC<IData> = ({ advertisements, limit, currentPage, isLoading, totalPages, itemId, isAgent, branchType, itemSlugName, onPageChange }) => {
    const getPaginationUrl = (page: number) => {
        return isAgent
            ? REAL_ESTATE_AGENT_DETAIL_TAB(itemId, itemSlugName, ERouterTabsAgent.ADVERTISEMENT, page)
            : REAL_ESTATE_BRANCH_DETAIL_TAB(itemId, itemSlugName, branchType as ERouterBranchType, ERouterTabsAgency.ADVERTISEMENT, page);
    };

    return <Stack direction='column' gap={5}>

        <Stack direction='column' gap={5}>
            {isLoading
                ? <Loading/>
                : <List advertisements={advertisements} currentPage={currentPage} limit={limit}/>
            }
        </Stack>
        {!isLoading && <Paginator
            showScrollToTopBtn
            isDisabled={isLoading}
            onChange={onPageChange}
            currentPage={currentPage}
            totalPageCount={totalPages}
            itemHref={(page) => getPaginationUrl(page)}
            prevHref={currentPage > 1 && getPaginationUrl(currentPage - 1)}
            nextHref={totalPages > currentPage && getPaginationUrl(currentPage + 1)}
        />}
    </Stack>;
};
