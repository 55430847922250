import React, { FC } from 'react';
import { IStepProps } from 'components/page/realEstate/common/interfaces/IStepProps';
import { Box, Grid } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import ArrowBackIcon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import { Button } from 'modules/theme/components/button/Button';

interface IProps extends IStepProps {
    textAreaField: React.ReactNode;
    buttonText: string;
    onButtonClick: () => void;
    isNextDisabled?: boolean;
    callText: string
}

export const StepTextArea: FC<IProps> = ({
    onIconClick,
    textAreaField,
    buttonText,
    onButtonClick,
    isNextDisabled,
    callText
}) => {
    return <>
        <Grid item xs={12}>
            <Link
                sx={{
                    cursor: 'pointer',
                }}
                noUnderline
                href='#'
                onClick={() => {
                    onIconClick();
                }}
            >
                <Box display='flex' alignContent='center' gap={1}>
                    <Svg icon
                        component={ArrowBackIcon}
                    />
                    <Text variant='body3'>
                        Kontaktné údaje
                    </Text>
                </Box>
            </Link>
        </Grid>

        <Grid item xs={12}>
            <Text variant='h6' bold>
                {callText}
            </Text>
        </Grid>

        <Grid item xs={12}>
            {textAreaField}
        </Grid>

        <Grid item xs={12}>
            <Grid container justifyContent='flex-end'>
                <Button text={buttonText} disabled={isNextDisabled} onClick={onButtonClick}/>
            </Grid>
        </Grid>
    </>;
};
