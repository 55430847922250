import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Button } from 'modules/theme/components/button/Button';

interface IProps {
    onPhoneButtonClick: () => void;
    onEmailButtonClick: () => void;
    isFetchingPhoneNumber: boolean;
}

export const PhoneEmailButtons: FC<IProps> = ({
    onPhoneButtonClick,
    onEmailButtonClick,
    isFetchingPhoneNumber,
}) => {
    return <>
        <Box
            display='flex'
            justifyContent='center'
            gap={2}
            py={1}
        >
            <Button
                disabled={isFetchingPhoneNumber}
                fullWidth
                text='Telefónne číslo'
                onClick={onPhoneButtonClick}
            />
            <Button
                fullWidth
                text='E-mail'
                onClick={onEmailButtonClick}
            />
        </Box>
    </>;
};
