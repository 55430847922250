import { useCallback } from 'react';
import { getCookie } from 'modules/cookie/getCookie';
import { setCookie } from 'modules/cookie/setCookie';

export interface IData {
    hasConvertedMobile: boolean;
    hasConvertedEmail: boolean;
    isRecentVisitFromRTB: boolean;
    wasPreviousVisitFromRTB: boolean
}

interface IReturn {
    get: () => IData | undefined | void;
    set: (data: IData) => void;
    isThisFirstVisitInSession: () => boolean;
}

const COOKIE_KEY_HISTORY = 'rtb_data_history';
const COOKIE_KEY_CONVERSION_MOBILE = 'rtb_data_conversion_mobile';
const COOKIE_KEY_CONVERSION_EMAIL = 'rtb_data_conversion_email';

export const useStorage = (): IReturn => {
    const get = useCallback(() => {
        let $history = getCookie(COOKIE_KEY_HISTORY)
            ? JSON.parse(decodeURIComponent(getCookie(COOKIE_KEY_HISTORY)))
            : {
                'isRecentVisitFromRTB' : false,
                'wasPreviousVisitFromRTB' : false
            };

        let $conversionMobile = getCookie(COOKIE_KEY_CONVERSION_MOBILE)
            ? JSON.parse(decodeURIComponent(getCookie(COOKIE_KEY_CONVERSION_MOBILE)))
            : {
                'hasConvertedMobile' : false,
            };

        let $conversionEmail = getCookie(COOKIE_KEY_CONVERSION_EMAIL)
            ? JSON.parse(decodeURIComponent(getCookie(COOKIE_KEY_CONVERSION_EMAIL)))
            : {
                'hasConvertedEmail' : false,
            };

        if (typeof $history === 'string') {
            $history = JSON.parse($history);
        }

        if (typeof $conversionMobile === 'string') {
            $conversionMobile = JSON.parse( $conversionMobile ) ;
        }

        if (typeof $conversionEmail === 'string') {
            $conversionEmail = JSON.parse( $conversionEmail );
        }

        return { ...$history, ...$conversionMobile, ...$conversionEmail };
    }, []);

    const set = useCallback(($data: IData) => {
        setCookie(COOKIE_KEY_HISTORY, JSON.stringify({
            wasPreviousVisitFromRTB: $data.wasPreviousVisitFromRTB,
            isRecentVisitFromRTB: $data.isRecentVisitFromRTB
        }), 60 * 60 * 24 * 30 * 6);

        setCookie(COOKIE_KEY_CONVERSION_MOBILE, JSON.stringify({
            hasConvertedMobile: $data.hasConvertedMobile,
        }), 60 * 30);

        setCookie(COOKIE_KEY_CONVERSION_EMAIL, JSON.stringify({
            hasConvertedEmail: $data.hasConvertedEmail,
        }), 60 * 30);
    }, []);

    const isThisFirstVisitInSession = useCallback(() => {
        return !getCookie(COOKIE_KEY_CONVERSION_MOBILE) && !getCookie(COOKIE_KEY_CONVERSION_EMAIL);
    }, []);

    return {
        get,
        set,
        isThisFirstVisitInSession,
    };
};
