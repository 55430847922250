import React, { FC } from 'react';
import { IStepProps } from 'components/page/realEstate/common/interfaces/IStepProps';
import { Box, Grid } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import ArrowBackIcon from 'modules/theme/components/icons/basic/ArrowBack.svg';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';

interface IProps extends IStepProps {
    contactInputsMerged: React.ReactElement;
    agreeCheckbox: React.ReactElement;
    recaptcha: React.ReactElement;
    submitButton: React.ReactElement;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
}

export const StepContactsAndSubmit: FC<IProps> = ({
    onIconClick,
    contactInputsMerged,
    agreeCheckbox,
    recaptcha,
    submitButton,
    handleSubmit,
}) => {
    return <>
        <Grid item xs={12}>
            <Link
                sx={{
                    cursor: 'pointer',
                }}
                noUnderline
                href='#'
                onClick={() => {
                    onIconClick();
                }}
            >
                <Box display='flex' alignContent='center' gap={1}>
                    <Svg icon
                        component={ArrowBackIcon}
                    />
                    <Text variant='body3'>
                        Späť
                    </Text>
                </Box>
            </Link>
        </Grid>

        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12} mb={2}>
                    <Text variant='h6' bold>
                        Vaše kontaktné údaje
                    </Text>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <Grid item xs={12} mb={2}>
                        {contactInputsMerged}
                    </Grid>


                    <Grid item xs={12} mb={2}>
                        {agreeCheckbox}
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end'>
                                    <Grid item>
                                        {recaptcha}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end'>
                                    {submitButton}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    </>;
};
