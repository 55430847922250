import { useState } from 'react';
import { realEstateAgencyDetailApi, realEstateAgentDetailApi } from 'modules/api/client';
import useLoading from 'modules/stores/loading/useLoading';
import useNotification from 'modules/notification/useNotification';
import { IPhoneNumbers } from 'modules/realEstate/common/interfaces/IPhoneNumbers';

interface IReturn {
    phoneNumbers: IPhoneNumbers | undefined;
    fetchPhoneApiCall: (token: string) => void;
    isFetching: boolean;
    isAgent?: boolean;
}

export const useShowPhoneNumber = (entityId: string, afterFetchPhone?: () => void, afterFetchPhoneError?: () => void, isAgent = false): IReturn => {
    const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumbers>();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const { start: startFetching, stop: stopFetching } = useLoading();
    const { error: showErrorNotification } = useNotification();

    const fetchPhoneApiCall = async (token) => {
        if (token && entityId) {
            try {
                startFetching();
                setIsFetching(true);
                if (!isAgent) {
                    const { data } = await realEstateAgencyDetailApi.getAgencyPhoneAgencyById(entityId, {
                        headers: {
                            'recaptcha-token': token,
                            'recaptcha-type': 'invisible',
                        }
                    });

                    setPhoneNumbers(data);

                } else {
                    const { data } = await realEstateAgentDetailApi.getByIdRealEstateAgentDetailContact(entityId, {
                        headers: {
                            'recaptcha-token': token,
                            'recaptcha-type': 'invisible',
                        }
                    });

                    setPhoneNumbers(data);
                }
                if (typeof afterFetchPhone === 'function') {
                    afterFetchPhone();
                }
            } catch (error) {
                showErrorNotification('Telefónne číslo sa nepodarilo načítať. Skúste prosím znovu.');
                if (typeof afterFetchPhoneError === 'function') {
                    afterFetchPhoneError();
                }
            } finally {
                setIsFetching(false);
                stopFetching();
            }
        }
    };

    return {
        phoneNumbers,
        fetchPhoneApiCall,
        isFetching,
    };
};
