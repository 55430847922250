import React from 'react';
import { useFormik } from 'formik';
import { IContactFormData } from 'components/page/realEstate/common/interfaces/IContactFormData';
import { contactFormValidationSchema as validationSchema } from 'components/page/realEstate/common/contactForm/constants/contactFormValidationSchema';
import { ContactFormTextArea } from 'components/page/realEstate/common/contactForm/fields/ContactFormTextArea';
import { InputsMerged } from 'components/page/realEstate/common/contactForm/fields/InputsMerged';
import { AgreeCheckbox } from 'components/page/realEstate/common/contactForm/fields/AgreeCheckbox';
import { Recaptcha } from 'components/recaptcha/components/Recaptcha';
import { Button } from 'modules/theme/components/button/Button';

interface IReturn {
    textAreaField: React.ReactElement;
    contactInputsMerged: React.ReactElement;
    agreeCheckbox: React.ReactElement;
    recaptcha: React.ReactElement;
    submitButton: React.ReactElement;
    textAreaValue?: string;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
    resetForm: () => void;
}

const INITIAL_FORM_VALUES = {
    text: 'Dobrý deň,\nkontaktujte ma, prosím, ohľadom informácií o ponúkaných nehnuteľnostiach.',
    name: '',
    email: '',
    phoneNumber: '',
    agreeToTerms: false,
    recaptchaToken: '',
};

export const useContactFormComponents = (onSubmit: (values: IContactFormData) => void, submitButtonText = 'Odoslať e-mail', fullWidth = true): IReturn => {
    const formik = useFormik<IContactFormData>({
        initialValues: INITIAL_FORM_VALUES,
        validationSchema,
        onSubmit,
        validateOnChange: true,
    });

    const { touched, errors } = formik;
    const textErrorMessage = touched.text && errors.text ? errors.text : undefined;
    const nameErrorMessage = touched.name && errors.name ? errors.name : undefined;
    const emailErrorMessage = touched.email && errors.email ? errors.email : undefined;
    const phoneNumErrorMessage = touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : undefined;
    const agreeToTermsErrorMessage = touched.agreeToTerms && errors.agreeToTerms ? errors.agreeToTerms : undefined;
    const recaptchaErrorMessage = touched.recaptchaToken && errors.recaptchaToken ? errors.recaptchaToken : undefined;

    return {
        textAreaField: <ContactFormTextArea
            name='text'
            value={formik.values.text}
            errorMsg={textErrorMessage}
            onChange={(name, value) => {formik.setFieldValue(name, value);}}
        />,
        contactInputsMerged: <InputsMerged
            valueName={formik.values.name}
            errorMessageName={nameErrorMessage}
            valueEmail={formik.values.email}
            errorMessageEmail={emailErrorMessage}
            valuePhoneNumber={formik.values.phoneNumber}
            errorMessagePhoneNumber={phoneNumErrorMessage}
            setValue={(field, value) => {
                formik.setFieldValue(field, value);
            }}
        />,
        agreeCheckbox: <AgreeCheckbox
            errorMsg={agreeToTermsErrorMessage}
            isChecked={formik.values.agreeToTerms}
            onChange={isChecked => formik.setFieldValue('agreeToTerms', isChecked)}
        />,
        recaptcha: <Recaptcha
            errorMsg={recaptchaErrorMessage}
            onChange={(token) => formik.setFieldValue('recaptchaToken', token)}
        />,
        submitButton: <Button text={submitButtonText} fullWidth={fullWidth} disabled={formik.isSubmitting} type='submit'/>,
        handleSubmit: formik.handleSubmit,
        resetForm: formik.resetForm,
        textAreaValue: formik.values.text
    };
};
