import React, { useCallback, useState } from 'react';
import Recaptcha, { ReCAPTCHA } from 'react-google-recaptcha';
import useTheme from 'modules/theme/state/useTheme';
import ETheme from 'modules/theme/state/ETheme';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

interface IReturn {
    component: JSX.Element;
    token: string;
    submitRecaptcha: () => void;
}

const useHiddenRecaptcha = (
    onTokenChange?: (token: string) => void
): IReturn => {
    const { theme } = useTheme();
    const [token, setToken] = useState<string>('');

    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const submitRecaptcha = useCallback(() => {
        recaptchaRef.current.execute();
    }, [recaptchaRef]);

    const resetToken = () => {
        setToken('');
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof window !== 'undefined' && window.IS_TESTING === true) {
        const testToken = 'test-token';
        return {
            component: (
                <button data-test-id='recaptcha' type='button' onClick={() => console.log(testToken)}>
                    Dummy reCAPTCHA
                </button>
            ),
            token: testToken,
            submitRecaptcha: () => {
                console.log(testToken);
            },
        };
    }

    return {
        component: <Recaptcha
            ref={recaptchaRef}
            theme={theme === ETheme.LIGHT ? 'light' : 'dark'}
            sitekey={AppConfigService.getGoogleInvisibleRecaptchaSitekey()}
            hl='sk'
            size='invisible'
            onChange={(token) => {
                const newToken = token || '';
                setToken(newToken);
                onTokenChange(newToken);
            }}
            onExpired={resetToken}
            onErrored={resetToken}
        />,
        token,
        submitRecaptcha,
    };
};

export default useHiddenRecaptcha;
