import { AdvertisementCard } from 'modules/theme/components/listing/cards/advertisementCard/components/card/AdvertisementCard';
import {
    IAdvertisement
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/IAdvertisement';
import { FC, useEffect } from 'react';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ADVERTISEMENT_DETAIL } from 'modules/route/routes';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import {
    getOrderIndex
} from 'modules/gtmEvents/helpers/gtmHelper';
import {
    getAdvertisementSelectItemEvent,
    getViewItemListAdvertisementsEvent,
    getAdvertisementSelectPromotionEvent,
    getViewItemListAdvertisementsPromotionTopEvent,
    getViewItemListAdvertisementsPromotionPremiumEvent
} from 'modules/gtmEvents/helpers/events/realEstate/realEstateEventsHelper';
import {
    getResetEcommerceEvent
} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { IGtmEvent } from 'modules/analytics/IAnalytics';
import {
    setViewItemListAdvertisementsRealEstateDetailEvents
} from 'components/page/realEstate/livemonitorEvents/helpers/events/eventsHelper';

interface IList {
    advertisements: IAdvertisement[];
    currentPage: number;
    limit: number;
}

export const List: FC<IList> = ({ advertisements, currentPage, limit }) => {
    const {
        gtm: { sendEvent: gtmSendEvent },
        livemonitor: { sendEvent: livemonitorSendEvent },
    } = useAnalytics();

    useEffect(() => {
        if (!advertisements.length) {
            return;
        }

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getViewItemListAdvertisementsEvent(advertisements, currentPage, limit),
        ];

        const premiumPromotedAdvertisements = advertisements.filter((adv) => adv.packages.isPremium);
        if (premiumPromotedAdvertisements.length) {
            events.push(getViewItemListAdvertisementsPromotionPremiumEvent(premiumPromotedAdvertisements, currentPage, limit));
        }

        const topPromotedAdvertisements = advertisements.filter((adv) => adv.packages.isPremium);
        if (topPromotedAdvertisements.length) {
            events.push(getViewItemListAdvertisementsPromotionTopEvent(topPromotedAdvertisements, currentPage, limit));
        }
        gtmSendEvent(...events);
        setViewItemListAdvertisementsRealEstateDetailEvents(livemonitorSendEvent, advertisements, 'plt-stats');
    }, [advertisements, currentPage, gtmSendEvent, limit, livemonitorSendEvent]);

    return <>
        {advertisements.map((advertisement, index) => {
            const advertisementDetailUrl
                    = `${AppConfigService.getNehnutelnostiUrl()}${ADVERTISEMENT_DETAIL(advertisement.id, advertisement.slug)}`;

            return <AdvertisementCard
                advertisement={advertisement}
                key={advertisement.id}
                detailUrl={advertisementDetailUrl}
                onClick={() => {
                    gtmSendEvent(
                        getResetEcommerceEvent(),
                        getAdvertisementSelectItemEvent(
                            advertisement,
                            getOrderIndex(index, currentPage, limit),
                        ),
                    );
                    if (advertisement.packages.isPremium || advertisement.packages.isTop) {
                        gtmSendEvent(
                            getAdvertisementSelectPromotionEvent(
                                advertisement,
                                getOrderIndex(index, currentPage, limit),
                            )
                        );
                    }
                }}
            />;
        })}
    </>;
};
