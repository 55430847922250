import React, { FC, ReactNode } from 'react';
import { Grid } from '@mui/material';

interface IStepsWrapper {
    children: ReactNode | ReactNode[];
}

export const StepsWrapper: FC<IStepsWrapper> = ({ children }) => {
    return <Grid
        container
        role='presentation'
        spacing={3}
        py={3}
        px={2}
    >
        {children}
    </Grid>;
};
