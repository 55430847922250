import { create } from 'zustand';
import ETheme from 'modules/theme/state/ETheme';

interface IReturn {
    theme: ETheme;
    setTheme: (theme: ETheme) => void;
}

type ThemeStore = {
    theme: ETheme;
    setTheme: (theme: ETheme) => void;
};

export const useThemeStore = create<ThemeStore>((set) => ({
    theme: ETheme.LIGHT,
    setTheme: (theme) => set({
        theme,
    }),
}));

const useTheme = (): IReturn => {
    const theme = useThemeStore((store) => store.theme);
    const setTheme = useThemeStore((store) => store.setTheme);

    return {
        theme,
        setTheme,
    };
};

export default useTheme;
