import { FC } from 'react';
import { LIMIT } from 'components/page/realEstate/common/advertisements/constants/limit';
import { Skeleton } from 'modules/theme/components/listing/cards/advertisementCard/components/skeleton/Skeleton';

export const Loading: FC = () => {
    const skeletons = [];
    for (let i = 0; i < LIMIT; i++) skeletons.push(<Skeleton key={i}/>);

    return <>
        {skeletons.map(skeleton => skeleton)}
    </>;
};
