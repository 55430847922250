import { FC } from 'react';
import {
    Skeleton as MobileSkeleton
} from 'modules/theme/components/listing/cards/advertisementCard/components/skeleton/mobile/Skeleton';
import {
    Skeleton as DesktopSkeleton
} from 'modules/theme/components/listing/cards/advertisementCard/components/skeleton/desktop/Skeleton';
import { Desktop, Mobile } from 'modules/theme/components/responsive';

export const Skeleton: FC = () => {
    return <>
        <Mobile>
            <MobileSkeleton/>
        </Mobile>
        <Desktop>
            <DesktopSkeleton/>
        </Desktop>
    </>;
};
