import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Skeleton as MuiSekeleton, Stack } from '@mui/material';

export const Skeleton: FC = () => {
    return <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>

        <MuiSekeleton width='33%' height={180} variant='rounded'/>

        <Stack width='66%' gap={2}>
            <Stack gap={.5}>
                <Text
                    center
                    semibold
                    variant='h4'
                >
                    <MuiSekeleton variant='text' width='40%'/>
                </Text>

                <Text
                    center
                    semibold
                    variant='body3'
                >
                    <MuiSekeleton variant='text' width='40%'/>
                    <MuiSekeleton variant='text' width='50%'/>
                </Text>

                <Text
                    center
                    semibold
                    variant='body3'
                >
                    <MuiSekeleton variant='text' width='100%'/>
                </Text>
            </Stack>

            <Stack direction='row' alignItems='end' justifyContent='space-between' width='100%'>
                <MuiSekeleton width={48} height={48} variant='circular'/>

                <Text
                    center
                    semibold
                    variant='h4'
                >
                    <MuiSekeleton variant='text' width={120}/>
                </Text>
            </Stack>
        </Stack>


    </Stack>;
};
